<template>
  <div class="elmain">
    <el-button type="success" @click="add">新增客户</el-button>

    <el-input style="width: 500px;    margin-left: 80px;" placeholder="请输入用户名称" v-model="name" autocomplete="off"></el-input>
    <el-button type="primary" @click="cx">查询</el-button>
    <el-table :data="list" border style="width: 100%;margin-top: 20px;">
      <el-table-column prop="name" label="客户名称" width="240"></el-table-column>
      <el-table-column prop="phone" label="客户电话" width="240"></el-table-column>
      <el-table-column prop="address" label="客户地址" width="240"></el-table-column>
      <el-table-column prop="created_at" label="创建时间" width="240"></el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="warning" size="small">编辑</el-button>
          <el-button type="danger" @click="deleteg(scope.row)" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="    margin-top: 10px;">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next" :total="total">
      </el-pagination>

    </div>

    <!-- 修改框 -->
    <el-dialog title="客户信息" :visible.sync="dialogFormVisible">
      <el-form :model="dialogData">
        <el-form-item label="客户名称" :label-width="formLabelWidth">
          <el-input v-model="dialogData.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="客户电话" :label-width="formLabelWidth">
          <el-input v-model="dialogData.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="客户地址" :label-width="formLabelWidth">
          <el-input v-model="dialogData.address" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getcustomer, addcustomer, putcustomer, deletecustomer } from "@/service/api";
export default {
  data () {
    return {
      tableData: [
      ],
      page: 1,
      name: '',
      limit: 10,
      list: [],
      total: 0,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      dialogData: {
        name: '',
        phone: '',
        address: ''
      },
      type: ''
    };
  },
  methods: {
    cx () {
      this.page = 1;
      this.getlist();
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getlist();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getlist();
    },
    handleClick (row) {
      console.log(row);
      this.dialogFormVisible = true;
      this.type = "put";
      this.dialogData = {

        name: row.name,
        phone: row.phone,
        address: row.address,
        id: row.id
      };
    },
    getlist () {
      let data = { limit: this.limit, page: this.page, name: this.name };
      console.log(data);
      getcustomer(data).then(res => {
        console.log(res);
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
    add () {
      this.dialogFormVisible = true;
      this.type = "add";
      this.dialogData = {
        name: '',
        phone: '',
        address: ''
      };

    },
    deleteg (i) {
      console.log(i);
      let that = this;
      this.$confirm('此操作将永久删除该客户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: i.id
        };
        deletecustomer(data).then(res => {
          if (res.code === 200) {
            that.getlist();
            that.$message({
              type: 'success',
              message: '删除成功!'
            });

          }

        });

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },

    submit () {
      console.log(this.dialogData);
      if (this.dialogData.name === '') {
        this.$message.error('请输入客户名');
        return;
      }


      if (this.type === 'add') {
        addcustomer(this.dialogData).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.getlist();
            this.dialogFormVisible = false;
          }

        });
      }
      if (this.type === 'put') {

        putcustomer(this.dialogData).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.getlist();
            this.dialogFormVisible = false;
          }
        });
      }

    }
  },
  mounted () {
    this.getlist();
  }
};
</script>
<style scoped>
.elmain {
  padding: 50px;
}
@media screen and (max-width: 750px) {
  .main-conent {
    margin: 10px;
  }
  .elmain[data-v-7ad48fd0] {
    padding: 10px;
  }
  .el-dialog {
    width: 80% !important;
  }
}
</style>